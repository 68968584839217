import React from 'react';

function Auth() {
  return (
    <div>
      Authenticating...
    </div>
  );
}

export default Auth;
